<template>
  <div :class="['fieldset', { 'has-error': $v.password.$error && checkValidate }]">
    <input
      id="login-password"
      v-model="$v.password.$model"
      :placeholder="placeholder"
      type="password"
    >
    <template v-if="checkValidate">
      <div
        v-if="!$v.password.required && $v.password.$error"
        class="error-message"
      >
        Укажите свой пароль
      </div>

      <div
        v-if="!$v.password.invalidPassword"
        class="error-message"
      >
        проверьте данные
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  name: 'FormPasswordField',

  mixins: [
    validationMixin,
  ],

  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Пароль',
    },
    submitError: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    checkValidate: false,
  }),

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    invalidPassword() {
      return this.submitError.some((e) => (e.invalidPassword));
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
      this.checkValidate = true;
      return !this.$v.password.$error;
    },
    reset() {
      this.checkValidate = false;
      this.password = '';
      this.$v.$reset();
    },
  },

  validations: {
    password: {
      required,
      invalidPassword() {
        return !this.invalidPassword;
      },
    },
  },
};
</script>

<style scoped lang="scss">

.fieldset {
  position: relative;

  &.has-error input {
    border-color: #FF0040 !important;
  }

  .error-message {
    color: #FF0040;
    text-align: right;
    font-size: 14px;
    line-height: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 20px;
    padding: 0 5px;
    background-color: $white-color;
  }
}

</style>
