<template>
  <div
    class="anapa-banner"
    tabindex="0"
    role="button"
    @click="$store.dispatch('modals/openAnapa24')"
  >
    <div class="anapa-banner__container">
      <span class="anapa-banner__cheap anapa-banner__10">-10%</span>
      <span class="anapa-banner__cheap anapa-banner__promo">акция до 01.09</span>
      <p class="anapa-banner__text anapa-banner__text-1">
        купите школу <br> со скидкой
      </p>
      <button
        class="m-btn"
        @click="$store.dispatch('modals/openAnapa24')"
      >
        узнать подробности
      </button>
      <p class="anapa-banner__text anapa-banner__text-2">
        выиграйте <br> тур в Анапу
      </p>
      <span class="anapa-banner__cheap anapa-banner__date">с 14.09-22.09</span>
      <span class="anapa-banner__cheap anapa-banner__all">all inclusive</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnapaBanner24',
};
</script>

<style lang="scss" scoped>
.anapa-banner {
  display: flex;
  height: calc(var(--scale) * 91);
  position: relative;

  background-image: url("/v2/global/anapa/bg.webp");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% calc(var(--scale) * 91);

  cursor: pointer;
  font-family: 'TildaSans', sans-serif;
  color: $white-color;

  @include media-down($size-tablet) {
    height: calc(var(--scale) * 50);
    background-size: cover;
    background-position: center;
    background-image: url("/v2/global/anapa/bg-tablet.webp");
  }

  @include media-down($size-mobile) {
    background-image: url("/v2/global/anapa/bg-mob.webp");
  }

  * {
    font-family: "Onest", sans-serif;
  }

  .anapa-banner__container {
    position: relative;
    left: calc(-1 * var(--scale) * 15);

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--scale) * 60);
    margin-top: auto;
    overflow: hidden;

    @include media-down($size-tablet) {
      left: calc(-1 *var(--scale) * 7);
      height: 100%;
      overflow: hidden;
    }

    @include media-down($size-mobile) {
      left: 0;
    }
  }

  .anapa-banner__cheap {
    padding: calc(var(--scale) * 5) calc(var(--scale) * 11);
    border-radius: calc(var(--scale) * 40);
    position: absolute;

    font-weight: 700;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 2) calc(var(--scale) * 5);
      font-size: calc(var(--scale) * 8);
    }
  }

  .anapa-banner__10 {
    background-color: #FF5319;
    transform: rotate(-4deg);

    left: calc(var(--scale) * 532);
    top: calc(var(--scale) * 3);

    @include media-down($size-tablet) {
      top: calc(var(--scale) * 16);
      left: calc(var(--scale) * 187);
    }

    @include media-down($size-mobile) {
      top: calc(var(--scale) * 22);
      left: calc(var(--scale) * 100);
    }
  }

  .anapa-banner__promo {
    background-color: #FFF0D1;
    transform: rotate(5deg);
    left: calc(var(--scale) * 470);
    top: calc(var(--scale) * 29);

    color: #FF5319;

    @include media-down($size-tablet) {
      transform: rotate(-3deg);
      left: calc(var(--scale) * 158);
      top: calc(var(--scale) * 29);
    }

    @include media-down($size-mobile) {
      left: calc(var(--scale) * 54);
      top: calc(var(--scale) * 35);
    }
  }

  .anapa-banner__text {
    font-weight: 700;
    font-size: calc(var(--scale) * 32);
    line-height: 80%;
    text-align: center;
    letter-spacing: -0.02em;

    @include media-down($size-tablet) {
      font-size: calc(var(--scale) * 16);
      letter-spacing: -0.02em;

      &-1 {
        text-align: right;
      }

      &-2 {
        text-align: left;
      }
    }

    @include media-down($size-mobile) {
      position: absolute;

      &-1 {
        left: calc(var(--scale) * 12);
        top: calc(var(--scale) * 4);
        text-align: left;
      }

      &-2 {
        right: calc(var(--scale) * 12);
        bottom: calc(var(--scale) * 4);
        text-align: right;
      }
    }
  }

  .anapa-banner__date {
    background-color: #5237E6;
    transform: rotate(2deg);
    right: calc(var(--scale) * 463);
    top: calc(var(--scale) * 32);

    @include media-down($size-tablet) {
      transform: rotate(-5deg);
      right: calc(var(--scale) * 138);
      top: calc(var(--scale) * 17);
    }

    @include media-down($size-mobile) {
      right: calc(var(--scale) * 84);
      top: calc(var(--scale) * 14);
    }
  }

  .anapa-banner__all {
    background-color: #E6CEFF;
    transform: rotate(6deg);
    right: calc(var(--scale) * 492);
    top: calc(var(--scale) * 2);

    color: #5237E6;

    @include media-down($size-tablet) {
      transform: rotate(-7deg);
      top: calc(var(--scale) * 7);
      right: calc(var(--scale) * 168);
    }

    @include media-down($size-mobile) {
      top: calc(var(--scale) * 4);
      right: calc(var(--scale) * 113);
    }
  }

  .m-btn {
    padding: calc(var(--scale) * 7) calc(var(--scale) * 20) calc(var(--scale) * 8);
    background-color: #FF9C12;
    border-radius: calc(var(--scale) * 16);
    transform: rotate(-1deg);
    margin-inline: calc(var(--scale) * 35);

    font-weight: 600;
    font-size: calc(var(--scale) * 22);
    line-height: 130%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      margin-inline: calc(var(--scale) * 20);
      padding: calc(var(--scale) * 5) calc(var(--scale) * 8) calc(var(--scale) * 7);
      max-width: calc(var(--scale) * 87);
      border-radius: calc(var(--scale) * 8);
      transform: rotate(-2deg);

      font-size: calc(var(--scale) * 11);
      line-height: 80%;
    }

    @include media-down($size-mobile) {
      display: none;
    }
  }
}
</style>
